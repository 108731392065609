export const clientNameFooter = "LE CORTEZ YOGA";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '3'; // width/height
export const aspectRatioLogoMain = '2'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "Letícia Lazzeri Cortez ";
export const nomeFornecedor = "LE CORTEZ YOGA";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/lecortezyoga/",
    profile: "@lecortezyoga"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/5511941156118",
    number: "(11) 94115-6118"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/5511941156118",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];